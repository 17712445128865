/**
 * InfiniteScroll handler.
 */
function infiniteScroll() {

	const el = 'button[type="submit"]';
	const $container = setContainer();
	var page = 1;
	var max_pages = IntegracaoSearch.max_pages;
	var posts_per_page = IntegracaoSearch.posts_per_page;
	var loading = false;

  // Filters
	$( '#searchform' ).on( 'click', el, searchAction );
	$( '#searchform' ).on( 'click', 'input[type=checkbox]', filterContent );

	// InfiniteScroll
	$( '.result-search' ).scroll( infiniteLoading );

	/**
	 * Check if there's a different container or instead use .products
	 *
	 * @return {string}   Container selector.
	 */
	function setContainer() {
		return $( '[data-filter="container-search"]' ).length ? $( '[data-filter="container-search"]' ) : $( '.dropdown-search' );
	}

  /**
   * 
   * function to prevent default
   */

  function searchAction(e) {
    e.preventDefault();

    filterContent(e);
   }

	/**
	 * Run AJAX request to filter consultants.
	 */
	function filterContent(e) {
    page = 1;
        
		fetchResults( getSearchValue(), getPostTypeFilters(), true );
	}

	/**
	 * Get search input value.
	 * @return {string} search value.
	 */
	function getSearchValue() {
    let inputValue = $('#searchform input[name=s]' ).val();

		return inputValue;
	}

  	/**
	 * Get search input value.
	 * @return {string} search value.
	 */
	function getPostTypeFilters() {
		let postTypeFilters = new Array('product', 'post'); //Argumentos para pesquisa
	
		$('#searchform input[type=checkbox]:checked' ).each(function(){
		if($(this).val() == 'others') {
			postTypeFilters.push('hotel', 'exclusivities', 'pages', 'consultor');
		} else {
			postTypeFilters.push($(this).val());
		}
		});

		return postTypeFilters;
	}
  
	/**
	 * InfiniteScroll check and trigger.
	 * @param  {[type]} event [description]
	 */
	function infiniteLoading( event ) {
		if ( page > max_pages ) {
			return;
		}

		var top_pos = $( '.result-search' ).scrollTop();
		var bottom_container = $( $container ).height() + $( $container ).position().top;

		if ( ! loading && top_pos + 500 > bottom_container ) {
			loading = true;
			fetchResults( getSearchValue(), getPostTypeFilters() );
		}
	}


	function fetchResults( searchValue, postTypeFilters, filtering = false ) {
		/**
		 * Ajax
		 */

		$('.load-search-results').fadeOut(100).addClass('d-none');

		$.ajax({
			url: IntegracaoSearch.ajax_url,
			type: 'POST',
			data: {
				action: IntegracaoSearch.action,
				page: page,
				posts_per_page: posts_per_page,
				search: searchValue,
				post_type_filters: ['product', 'post', ...postTypeFilters],
				nonce: IntegracaoSearch.nonce
			},
			beforeSend: function() {
				if (filtering) {
					$($container).empty();
				}
	
				$($container).append('<div class="loader"><div class="box1"></div><div class="box2"></div><div class="box3"></div></div>');
			},
			success: function(result) {
				if (result.success) {
					page++;
					max_pages = result.data.max_pages;
					$($container).append(result.data.content);
				} else {
					$($container).empty();
					$($container).append('<h2 class="py-5">' + result.data + '</h2>');
				}
			},
			error: function(errorThrown) {
				console.log(errorThrown);
			},
			complete: function() {
				loading = false;
				$('.loader').remove();
			}
		});
	}
}
infiniteScroll();
